import React, { useEffect, useState } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  SCHEMA_TYPE_MULTI_ENUM,
  SCHEMA_TYPE_TEXT,
  propTypes,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { convertMoneyToNumber } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import { confirmCardPayment, handleCardSetup, initializeCardPaymentData, retrievePaymentIntent } from '../../ducks/stripe.duck.js';
import {
  H4,
  Page,
  NamedRedirect,
  OrderPanel,
  LayoutSingleColumn,
  Modal,
  IconCollection,
  ListingCard,
  NamedLink,
} from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import {
  sendInquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
} from './ListingPage.duck';

import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
  handleContactUser,
  handleSubmitInquiry,
  handleSubmit,
} from './ListingPage.shared';
import ActionBarMaybe from './ActionBarMaybe';
import SectionTextMaybe from './SectionTextMaybe';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';
import SectionReviews from './SectionReviews';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionGallery from './SectionGallery';

import css from './ListingPage.module.css';
import classNames from 'classnames';
import AuthenticationForm from '../../components/AuthenticationForm/AuthenticationForm';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import {
  confirmPayment,
  confirmTransactionExtraAttributes,
  createPaymentIntentWithStripe,
  fetchAuthorDetails,
  stripeCustomer,
  initiateOffer
} from '../CheckoutPage/CheckoutPage.duck';

import OfferPanel from './OfferPanel/OfferPanel';
import { getAuthorData } from '../../util/api';
import Slider from 'react-slick';
import { createStripeSetupIntent } from '../PaymentMethodsPage/PaymentMethodsPage.duck';
import { savePaymentMethod } from '../../ducks/paymentMethods.duck';
import ProductSlider from './productSlider.js';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID, Money } = sdkTypes;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

export const ListingPageComponent = props => {
  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );
  const [isMakeAnOfferModal, setIsMakeAnOfferModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsModalOpen(false);
  }, [props.isAuthenticated]);

  const {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    reviews,
    fetchReviewsError,
    sendInquiryInProgress,
    sendInquiryError,
    monthlyTimeSlots,
    onFetchTimeSlots,
    listingConfig: listingConfigProp,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    onSendInquiry,
    onInitializeCardPaymentData,
    config,
    routeConfiguration,
    listings,
    stripeCustomerFetched,
    onInitiateOffer,
    ...rest
  } = props;

  // prop override makes testing a bit easier
  // TODO: improve this when updating test setup
  const listingConfig = listingConfigProp || config.listing;
  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'details';

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;
  const { LatLng } = sdkTypes;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const topbar = <TopbarContainer />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const authorProfile = (
    <NamedLink name="ProfilePage" params={{ id: ensuredAuthor?.id?.uuid }}>
      @{authorDisplayName}
    </NamedLink>
  );

  const newPrice = new Money(price?.amount, price?.currency);

  const { formattedPrice } = priceData(newPrice, config.currency, intl);

  const commonParams = { params, history, routes: routeConfiguration };
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });
  // Note: this is for inquiry state in booking and purchase processes. Inquiry process is handled through handleSubmit.
  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen,
  });
  const onSubmit = handleSubmit({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    getListing,
    onInitializeCardPaymentData,
  });

  const handleOrderSubmit = values => {
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {
      if (!currentUser?.id) {
        history.push('/login');
      } else {
        onSubmit(values);
      }
    }
  };

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, marketplaceName }
  );
  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = newPrice?.amount
    ? {
      price: intl.formatNumber(convertMoneyToNumber(newPrice), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      priceCurrency: newPrice.currency,
    }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;

  const { bagItems = [], phoneNumber } =
    (!!currentUser?.id && currentUser.attributes.profile.protectedData) || {};

  const { apartment, city, postalCode, state, country } =
    currentUser?.attributes?.profile?.protectedData?.location || {};
  const userLocation = currentUser?.attributes?.profile?.protectedData.location || {};

  const initialValues = {
    apartment,
    city,
    postalCode,
    state,
    phoneNumber,
    country,
    address: userLocation?.address,
  };

  const isOutOfStock = currentStock === 0;
  const { allowNegotiation, markForSale } = currentListing?.attributes?.publicData;

  if (userLocation && userLocation.origin && userLocation.origin.lat && userLocation.address) {
    const { address, origin } = userLocation;
    const { lat, lng } = origin;
    const geolocation = new LatLng(lat, lng);

    initialValues.location = {
      search: address,
      selectedPlace: { address, origin: geolocation },
    };
  }

  const schemaAvailability =
    currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

  const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));

  const makeAnOfferButton = (
    <div className={css.makeAnOfferButton}>
      <button
        disabled={isOwnListing || isOutOfStock || allowNegotiation == "no" || !markForSale || currentListing?.attributes?.state === LISTING_STATE_CLOSED}
        className={classNames(css.removedCard, css.removeCard)}
        onClick={() => {
          if (!currentUser?.id) {
            setIsModalOpen(true);
          } else {
            setIsMakeAnOfferModal(true);
          }


        }}
      >
        <IconCollection icon="makeAnOffer" />
        <FormattedMessage id="ListingPage.makeAnOffer" />
      </button>
    </div>
  );

  const loginRequriedModal = (
    <Modal
      id="loginFormRequriedModal"
      isOpen={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
      }}
      isLandingPage={false}
      usePortal
      contentClassName={css.modalContent}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <div>
        <AuthenticationForm isModalOpen={isModalOpen} />
      </div>
    </Modal>
  );

  const id = listingId?.uuid;
  const index = bagItems && bagItems.findIndex(e => e == id);
  const handleBagItems = () => {
    if (!currentUser?.id) {
      setIsModalOpen(true);
    } else {
      if (index > -1) {
        bagItems.splice(index, 1);
      } else {
        bagItems.push(id);
      }
      const profile = {
        protectedData: {
          bagItems,
        },
      };
      dispatch(updateProfile(profile));
    }
  };
  const addToBagAndRemoveFromBag = (
    <div className={css.addToCartBtn}>
      <button
        disabled={isOwnListing || !markForSale || currentListing?.attributes?.state === LISTING_STATE_CLOSED}
        className={classNames(css.removeCard)}
        onClick={e => {
          handleBagItems();
        }}
      >
        {<IconCollection icon={'addToBag'} />}{' '}
        <FormattedMessage id={index > -1 ? 'ListingPage.removeToBag' : 'ListingPage.addToBag'} />
      </button>
    </div>
  );

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    draggable: false,
    arrows: listings && listings?.filter((e) => e.id.uuid != id)?.length > 2 ? true : false,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          availability: schemaAvailability,
        },
      }}
    >
      <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={<FooterContainer />}>
        <div className={css.contentWrapperForProductLayout}>
          <div className={css.mainColumnForProductLayout}>
            <div className={css.closeBack} onClick={() => history.goBack()}>
              <IconCollection icon="IconClose" />
            </div>
            <OfferPanel
              onInitiateOffer={onInitiateOffer}
              currentListing={currentListing}
              stripeCustomerFetched={stripeCustomerFetched}
              currentUser={currentUser}
              routeConfiguration={routeConfiguration}
              history={history}
              onSubmitInquiry={onSubmitInquiry}
              isMakeAnOfferModal={isMakeAnOfferModal}
              handleOfferModal={() => {
                setIsMakeAnOfferModal(false);
              }}
              authorAvailable={authorAvailable}
              config={config}
              {...rest}
            />
            {loginRequriedModal}
            {currentListing.id && currentStock !== 0 ? (
              <ActionBarMaybe
                className={css.actionBarForProductLayout}
                isOwnListing={isOwnListing}
                listing={currentListing}
                editParams={{
                  id: listingId.uuid,
                  slug: listingSlug,
                  type: listingPathParamType,
                  tab: listingTab,
                }}
              />
            ) : null}
            <div className={css.mobileContainer}>
              <div className={css.mobileHeading}>
                <H4 as="h1" className={css.orderPanelTitle}>
                  <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                </H4>
              </div>
              <div className={css.orginalPrice}>
                <FormattedMessage id="OrderPanel.originalPrice" />
                {(publicData?.originalPrice || publicData?.prices?.retailPrice / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
              </div>
              <div className={css.priceContainer}>
                <FormattedMessage id="OrderPanel.askingPrice" />
                {(price && price.amount / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
              </div>
            </div>
            <SectionGallery
              currentUser={currentUser}
              listing={currentListing}
              variantPrefix={config.layout.listingImage.variantPrefix}
              isOwnListing={isOwnListing}
              intl={intl}
            />

            <SectionTextMaybe text={description} showAsIngress />
            <SectionDetailsMaybe
              publicData={publicData}
              metadata={metadata}
              listingConfig={listingConfig}
              intl={intl}
            />
            {listingConfig.listingFields.reduce((pickedElements, config) => {
              const { key, enumOptions, includeForListingTypes, scope = 'public' } = config;
              const listingType = publicData?.listingType;
              const isTargetListingType =
                includeForListingTypes == null || includeForListingTypes.includes(listingType);

              const value =
                scope === 'public' ? publicData[key] : scope === 'metadata' ? metadata[key] : null;
              const hasValue = value != null;
              return isTargetListingType && config.schemaType === SCHEMA_TYPE_MULTI_ENUM
                ? [
                  ...pickedElements,
                  <SectionMultiEnumMaybe
                    key={key}
                    heading={config?.showConfig?.label}
                    options={createFilterOptions(enumOptions)}
                    selectedOptions={value || []}
                  />,
                ]
                : isTargetListingType && hasValue && config.schemaType === SCHEMA_TYPE_TEXT
                  ? [
                    ...pickedElements,
                    <SectionTextMaybe key={key} heading={config?.showConfig?.label} text={value} />,
                  ]
                  : pickedElements;
            }, [])}

            <SectionMapMaybe
              geolocation={geolocation}
              publicData={publicData}
              listingId={currentListing.id}
              mapsConfig={config.maps}
            />
            {/* <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} /> */}
            <SectionAuthorMaybe
              title={title}
              listing={currentListing}
              authorDisplayName={authorDisplayName}
              onContactUser={onContactUser}
              isInquiryModalOpen={isAuthenticated && inquiryModalOpen}
              onCloseInquiryModal={() => setInquiryModalOpen(false)}
              sendInquiryError={sendInquiryError}
              sendInquiryInProgress={sendInquiryInProgress}
              onSubmitInquiry={onSubmitInquiry}
              currentUser={currentUser}
              onManageDisableScrolling={onManageDisableScrolling}
            />
          </div>
          <div className={css.orderColumnForProductLayout}>
            <OrderPanel
              className={css.productOrderPanel}
              listing={currentListing}
              isOwnListing={isOwnListing}
              onSubmit={handleOrderSubmit}
              authorLink={
                // <NamedLink
                //   className={css.authorNameLink}
                //   name="ListingPage"
                //   params={params}
                //   to={{ hash: '#author' }}
                // >
                authorDisplayName
                // </NamedLink>
              }
              title={<FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />}
              titleDesktop={
                <H4 as="h1" className={classNames(css.orderPanelTitle, css.orderPanelTitleDesktop)}>
                  <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                </H4>
              }
              author={ensuredAuthor}
              onManageDisableScrolling={onManageDisableScrolling}
              onContactUser={onContactUser}
              monthlyTimeSlots={monthlyTimeSlots}
              onFetchTimeSlots={onFetchTimeSlots}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              validListingTypes={config.listing.listingTypes}
              marketplaceCurrency={config.currency}
              dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
              marketplaceName={config.marketplaceName}
              makeAnOfferButton={makeAnOfferButton}
              addToBagAndRemoveFromBag={addToBagAndRemoveFromBag}
            />
          </div>
        </div>
        {listings && listings?.filter((e) => e.id.uuid != id)?.length > 0 && (
          <div className={css.sliderContent}>
            <div className={css.sliderInnerbox}>
              <div>
                <h4 className={css.sliderTitle}>
                  <FormattedMessage
                    id="ListingPage.moreListings"
                    values={{ userName: authorProfile }}
                  />
                </h4>
              </div>
              <div className={css.listingCardsScroll}>

                <ProductSlider
                  listings={listings}
                  id={id}
                />
              </div>
            </div>
          </div>
        )}
      </LayoutSingleColumn>
    </Page>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  listingConfig: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  listingConfig: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
    listings,
  } = state.ListingPage;
  const {
    stripeCustomerFetched,
    orderNumber,
    orderNumberError,
    fecthRatesSuccess,
    fecthRatesInProgress,
    defaultRates,
  } = state.CheckoutPage;
  const { validateAddress } = state.ProfileSettingsPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
    listings,
    stripeCustomerFetched,
    orderNumber,
    orderNumberError,
    fecthRatesSuccess,
    fecthRatesInProgress,
    defaultRates,
    validateAddress,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params =>
    dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message) =>
    dispatch(sendInquiry(listing, message)),
  onInitializeCardPaymentData: () =>
    dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onFetchAuthorDetails: params =>
    dispatch(fetchAuthorDetails(params)),
  onCreatePaymentIntentWithStripe: (orderParams, requestPaymentParams) =>
    dispatch(createPaymentIntentWithStripe(orderParams, requestPaymentParams)),
  onConfirmCardPayment: params =>
    dispatch(confirmCardPayment(params)),
  onConfirmPayment: (transactionId, transitionParams) =>
    dispatch(confirmPayment(transactionId, transitionParams)),
  onRetrievePaymentIntent: params =>
    dispatch(retrievePaymentIntent(params)),
  fetchStripeCustomer: () =>
    dispatch(stripeCustomer()),
  onHandleCardSetup: params =>
    dispatch(handleCardSetup(params)),
  onCreateSetupIntent: params =>
    dispatch(createStripeSetupIntent(params)),
  onSavePaymentMethod: (stripeCustomer, newPaymentMethod) =>
    dispatch(savePaymentMethod(stripeCustomer, newPaymentMethod)),
  onConfirmTransactionExtraAttributes: params =>
    dispatch(confirmTransactionExtraAttributes(params)),
  onInitiateOffer: (orderParams, stripePaymentMethodId) =>
    dispatch(initiateOffer(orderParams))
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(connect(mapStateToProps, mapDispatchToProps))(EnhancedListingPage);

export default ListingPage;
