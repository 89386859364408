import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';

// ================ Action types ================ //

export const FAVORITE_LISTING_REQUEST = 'app/FavouritesPage/FAVORITE_LISTING_REQUEST';
export const FAVORITE_LISTING_SUCCESS = 'app/FavouritesPage/FAVORITE_LISTING_SUCCESS';
export const FAVORITE_LISTING_ERROR = 'app/FavouritesPage/FAVORITE_LISTING_ERROR';


// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: {},
  queryInProgress: false,
  favoriteListingError: null,
  userListingRefs: [],
};

const listingPageReducer = (state = initialState, action = {}) => {

  const { type, payload } = action;
  switch (type) {
    case FAVORITE_LISTING_REQUEST:
      return {
        ...state,
        queryInProgress: true,
        favoriteListingError: null,
      };
    case FAVORITE_LISTING_SUCCESS:
      return { ...state, queryInProgress: true, userListingRefs: payload };

    case FAVORITE_LISTING_ERROR:
      return { ...state, queryInProgress: false, favoriteListingError: payload };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const favoriteListingRequest = queryParams => ({
  type: FAVORITE_LISTING_REQUEST,
  payload: queryParams,
});

export const favoriteListingSuccess = listingRefs => ({
  type: FAVORITE_LISTING_SUCCESS,
  payload: listingRefs
});

export const favoriteListingError = e => ({
  type: FAVORITE_LISTING_ERROR,
  error: true,
  payload: e,
});

export const searchListings = (ids) => async (dispatch, getState, sdk) => {
  dispatch(favoriteListingRequest());
  const params = {
    ids,
    include: ['author', 'images','currentStock'],
    'limit.images': 1,
  };
  try {


    const response = await sdk.listings.query(params)
    const { data: { data = {} } = {} } = response || {};
    const listingRefs = !!data?.length && data?.map(({ id, type }) => ({ id, type }));
    dispatch(addMarketplaceEntities(response));
    dispatch(favoriteListingSuccess(listingRefs));

    return response;
  } catch (e) {

    dispatch(favoriteListingError(storableError(e)));
    throw e;
  }
};

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  try {
    const currentUser = await dispatch(fetchCurrentUser());
    const { likeItems = [] } = currentUser?.attributes?.profile?.protectedData || {}
    dispatch(searchListings(likeItems))
  } catch (e) {
    dispatch(favoriteListingError(storableError(e)));
  }
};
