import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  IconCollection,
} from '../../../components';

import css from './LoginForm.module.css';
import { FORGET } from '../../../util/types';

const LoginFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        intl,
        invalid,
        isPasswordVisibleFunction,
        isPasswordVisible,
        handlePasswordVisibilityToggle,
        handleSignupLoginForm,
        history
      } = fieldRenderProps;

      // email
      const emailLabel = intl.formatMessage({
        id: 'LoginForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'LoginForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'LoginForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'LoginForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordLabel = intl.formatMessage({
        id: 'LoginForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'LoginForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'LoginForm.passwordRequired',
      });
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const passwordRecoveryLink = (
        <span
          onClick={() => {
            history.push('/recover-password')
          }}
        >
          <FormattedMessage id="LoginForm.forgotPassword" />
        </span>
      );
      const IconPassword = isPasswordVisible ? (
        <div className={css.text} onClick={handlePasswordVisibilityToggle}>
          <IconCollection icon="passwordShowIcon" />
        </div>
      ) : (
        <div className={css.text} onClick={handlePasswordVisibilityToggle}>
          <IconCollection icon="passwordHideIcon" />
        </div>
      );

      const newAccount = (
        <span
          onClick={() => {
            history.push('/signup');
          }}
          className={css.heighlightText}
        >
          <FormattedMessage id="LoginForm.singup" />
        </span>
      );

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <h1 className={css.title}>
            <FormattedMessage id="SignupForm.heading" />
          </h1>
          <h3 className={css.Subtitle}>
            <FormattedMessage id="LoginForm.heading1" />
          </h3>
          <h5 className={css.signInText}>
            <FormattedMessage id="LoginForm.heading2" />
          </h5>
          <div className={css.formWrapper}>
            <FieldTextInput
              type="email"
              id={
                formId
                  ? `${formId}.password.${Math.random()
                      .toString(36)
                      .substring(2, 15)}`
                  : `password.${Math.random()
                      .toString(36)
                      .substring(2, 15)}`
              }
              name="email"
              autoComplete="email"
              // label={emailLabel}
              placeholder={emailPlaceholder}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <FieldTextInput
              className={css.password}
              type={isPasswordVisibleFunction(isPasswordVisible, 'password')}
              id={
                formId
                  ? `${formId}.password.${Math.random()
                      .toString(36)
                      .substring(2, 15)}`
                  : `password.${Math.random()
                      .toString(36)
                      .substring(2, 15)}`
              }
              name="password"
              autoComplete="current-password"
              placeholder={passwordPlaceholder}
              validate={passwordRequired}
              inputIcon={IconPassword}
              inputWithImage={css.inputWithImage}
            />
          </div>
          <div className={css.bottomWrapper}>
            <p className={css.bottomWrapperText}>
              <span className={css.recoveryLinkInfo}>
                {passwordRecoveryLink}
                {/* <FormattedMessage
                  id="LoginForm.forgotPasswordInfo"
                  values={{ passwordRecoveryLink }}
                /> */}
              </span>
            </p>
            <PrimaryButton
              className={css.loginBtn}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
            >
              <FormattedMessage id="LoginForm.logIn" />
            </PrimaryButton>
            <p className={css.formloginLInk}>
              <FormattedMessage id="LoginForm.newAccount" values={{ newAccount }} />
            </p>
          </div>
        </Form>
      );
    }}
  />
);

LoginFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
};

const { string, bool } = PropTypes;

LoginFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

const LoginForm = compose(injectIntl)(LoginFormComponent);
LoginForm.displayName = 'LoginForm';

export default LoginForm;
