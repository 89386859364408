import pick from 'lodash/pick';
import {
  addOrUpdateOrderNumber,
  addOrUpdateTransactionData,
  createShipment,
  createStripePaymentIntent,
  fetchCoupons,
  fetchOrderNumber,
  getAuthorData,
  initiatePrivileged,
  transitionPrivileged,
  updateAlgoliaData,
  updateCouponStatus,
  updateListingWithTransaction,
  updateTransactionMetadata,
} from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { fetchCurrentUserHasOrdersSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import { fetchMessages } from '../TransactionPage/TransactionPage.duck';
import { transitions } from '../../transactions/transactionDotsProcessPurchase';
import { DOTS_PROCESS_NAME } from '../../transactions/transaction';
import { deleteListingStock } from './CheckoutPageSessionHelpers';
import { addressValidationWithShippo, updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import usStates from 'us-state-codes';
import { reminderMailNotification } from '../ListingPage/ListingPage.duck';
import { getTimestampAfterAdding14Hours, getUserDetails } from '../../util/dataExtractor';
import { ORDER_NUMBER_START_FROM } from '../../util/types';
import moment from 'moment';
// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/CheckoutPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/CheckoutPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/CheckoutPage/SPECULATE_TRANSACTION_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutPage/STRIPE_CUSTOMER_ERROR';

export const INITIATE_INQUIRY_REQUEST = 'app/CheckoutPage/INITIATE_INQUIRY_REQUEST';
export const INITIATE_INQUIRY_SUCCESS = 'app/CheckoutPage/INITIATE_INQUIRY_SUCCESS';
export const INITIATE_INQUIRY_ERROR = 'app/CheckoutPage/INITIATE_INQUIRY_ERROR';

export const CREATE_PAYMENT_INTENT_REQUEST = 'app/CheckoutPage/CREATE_PAYMENT_INTENT_REQUEST';
export const CREATE_PAYMENT_INTENT_SUCCESS = 'app/CheckoutPage/CREATE_PAYMENT_INTENT_SUCCESS';
export const CREATE_PAYMENT_INTENT_ERROR = 'app/CheckoutPage/INITIATE_INQUIRY_ERROR';

export const FETCH_ORDER_NUMBER_SUCCESS = 'app/CheckoutPage/FETCH_ORDER_NUMBER_SUCCESS';
export const FETCH_ORDER_NUMBER_ERROR = 'app/CheckoutPage/FETCH_ORDER_NUMBER_ERROR';

export const APPLY_COUPON_REQUEST = 'app/CheckoutPage/APPLY_COUPON_REQUEST';
export const APPLY_COUPON_SUCCESS = 'app/CheckoutPage/APPLY_COUPON_SUCCESS';
export const APPLY_COUPON_ERROR = 'app/CheckoutPage/APPLY_COUPON_ERROR';

export const FETCH_SHIPPING_RATES_REQUEST = 'app/CheckoutPage/FETCH_SHIPPING_RATES_REQUEST';
export const FETCH_SHIPPING_RATES_SUCCESS = 'app/CheckoutPage/FETCH_SHIPPING_RATES_SUCCESS';
export const FETCH_SHIPPING_RATES_ERROR = 'app/CheckoutPage/FETCH_SHIPPING_RATES_ERROR';

const indexName = process.env.REACT_APP_ALGOLIA_LISTING_INDEX;

// ================ Reducer ================ //

const initialState = {
  listing: null,
  orderData: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  stripeCustomerFetched: false,
  initiateInquiryInProgress: false,
  initiateInquiryError: null,
  paymentInProgress: false,
  paymentSuccess: true,
  paymentError: null,
  orderNumber: 2000,
  orderNumberError: null,
  applyCouponInprogress: false,
  applyCouponSuccess: null,
  applyCouponError: null,
  fecthRatesInProgress: false,
  fecthRatesSuccess: [],
  fecthRatesError: null,
  defaultRates: {},
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
      };
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };
    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };

    case INITIATE_INQUIRY_REQUEST:
      return { ...state, initiateInquiryInProgress: true, initiateInquiryError: null };
    case INITIATE_INQUIRY_SUCCESS:
      return { ...state, initiateInquiryInProgress: false };
    case INITIATE_INQUIRY_ERROR:
      return { ...state, initiateInquiryInProgress: false, initiateInquiryError: payload };
    case FETCH_ORDER_NUMBER_SUCCESS:
      return {
        ...state,
        orderNumber: payload
      };
    case FETCH_ORDER_NUMBER_ERROR:
      return {
        ...state,
        orderNumberError: payload
      };

    case APPLY_COUPON_REQUEST:
      return { ...state, applyCouponInprogress: true, applyCouponError: null };
    case APPLY_COUPON_SUCCESS:
      return { ...state, applyCouponInprogress: false, applyCouponError: null, applyCouponSuccess: payload };
    case APPLY_COUPON_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, applyCouponInprogress: false, applyCouponError: payload, applyCouponSuccess: null };

    case FETCH_SHIPPING_RATES_REQUEST:
      return { ...state, fecthRatesInProgress: true, fecthRatesError: null };
    case FETCH_SHIPPING_RATES_SUCCESS:
      return { ...state, fecthRatesInProgress: false, fecthRatesError: null, fecthRatesSuccess: payload.shippingRates, defaultRates: payload.defaultShipping };
    case FETCH_SHIPPING_RATES_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, fecthRatesInProgress: false, fecthRatesError: payload, applyCouponSuccess: null };


    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

export const initiateInquiryRequest = () => ({ type: INITIATE_INQUIRY_REQUEST });
export const initiateInquirySuccess = () => ({ type: INITIATE_INQUIRY_SUCCESS });
export const initiateInquiryError = e => ({
  type: INITIATE_INQUIRY_ERROR,
  error: true,
  payload: e,
});

export const fetchOrderNumberSuccess = orderNumber => ({
  type: FETCH_ORDER_NUMBER_SUCCESS,
  payload: orderNumber,
});
export const fetchOrderNumberError = e => ({
  type: FETCH_ORDER_NUMBER_ERROR,
  error: true,
  payload: e,
});

const applyCouponRequest = () => ({ type: APPLY_COUPON_REQUEST });

const applyCouponSuccess = data => ({
  type: APPLY_COUPON_SUCCESS,
  payload: data,
});

const applyCouponError = e => ({
  type: APPLY_COUPON_ERROR,
  error: true,
  payload: e,
});

const fetchRatesRequest = () => ({ type: FETCH_SHIPPING_RATES_REQUEST });

const fetchRatesSuccess = ({ shippingRates, defaultShipping }) => ({
  type: FETCH_SHIPPING_RATES_SUCCESS,
  payload: { shippingRates, defaultShipping },
});

const fetchRatesError = e => ({
  type: FETCH_SHIPPING_RATES_ERROR,
  error: true,
  payload: e,
});

/* ================ Thunks ================ */

export const initiateOrder = (
  orderParams,
  processAlias,
  transactionId,
  transitionName,
  isPrivilegedTransition
) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;

  const { deliveryMethod, quantity, bookingDates, ...otherOrderParams } = orderParams;
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};
  const bookingParamsMaybe = bookingDates || {};

  // Parameters only for client app's server
  const orderData = deliveryMethod ? { deliveryMethod } : {};

  // Parameters for Marketplace API
  const transitionParams = {
    ...quantityMaybe,
    ...bookingParamsMaybe,
    ...otherOrderParams,
  };

  const bodyParams = isTransition
    ? {
      id: transactionId,
      transition: transitionName,
      params: transitionParams,
    }
    : {
      processAlias,
      transition: transitionName,
      params: transitionParams,
    };
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSucces = response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    throw e;
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: false, orderData, bodyParams, queryParams })
      .then(handleSucces)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transition(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: false, orderData, bodyParams, queryParams })
      .then(handleSucces)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiate(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  }
};

export const addOrderNumber = orderNumber => {
  try {
    addOrUpdateOrderNumber({ orderNumber: orderNumber ? orderNumber + 1 : ORDER_NUMBER_START_FROM }).then(res => {
      return res
    });
  } catch (error) {
    console.log(error, "error")
  }
};

export const updateCoupon = couponData => {
  try {
    updateCouponStatus({ couponName: couponData?.codeName }).then(res => {
      return res
    });
  } catch (error) {
    console.log(error, "error")
  }
};

export const applyCoupon = data => (dispatch, getState, sdk) => {
  const { couponCode } = data || {};
  dispatch(applyCouponRequest());
  try {
    fetchCoupons({ couponCode }).then(res => {
      if (res) {
        dispatch(applyCouponSuccess(res));
        return res
      }
    });
  } catch (error) {
    console.log(error, "error")
    dispatch(applyCouponError(error));
  }
};

export const confirmTransactionExtraAttributes = (params) => (
  dispatch,
  getState,
  sdk
) => {
  const { orderNumber, couponData, transactionId, cartItems, listing, isOffer = false } = params || {};

  const listingId = listing?.id?.uuid || '';
  const { currentUser } = getState().user || {};
  const bagItems = currentUser?.attributes?.profile?.protectedData?.bagItems || [];

  const updatedBagItems = cartItems?.length
    ? bagItems.filter(item => !cartItems.some(cartItem => cartItem.listingId === item))
    : bagItems;

  const cartItemPromises = cartItems?.length
    ? cartItems.map(item => {
      const itemListingId = item?.listingId;
      if (itemListingId && !isOffer) {
        return [
          Promise.resolve(updateListingWithTransaction({ listingId: itemListingId, transactionId })), // Ensuring a Promise
          updateAlgoliaData({ indexName, objectID: itemListingId, state: 'closed' })
        ];
      }
      return [];
    }).flat()
    : [];

  const additionalPromises = isOffer
    ? [dispatch(updateProfile({ protectedData: { bagItems: updatedBagItems } }))]
    : cartItems?.length
      ? [
        deleteListingStock(cartItems, listing),
        dispatch(updateProfile({ protectedData: { bagItems: updatedBagItems } }))
      ]
      : [
        Promise.resolve(updateListingWithTransaction({ listingId, transactionId })), // Ensuring a Promise
        updateAlgoliaData({ indexName, objectID: listingId, state: 'closed' })
      ];

  const generalPromises = isOffer
    ? [addOrderNumber(orderNumber)]
    : [
      addOrderNumber(orderNumber),
      // updateCoupon(couponData)
    ];

  const allPromises = [...cartItemPromises, ...additionalPromises, ...generalPromises];

  return Promise.all(allPromises)
    .then((results) => {
      console.log('Transaction confirmed successfully:', results);
    })
    .catch((error) => {
      console.error('Error confirming transaction:', error);
    });
};

export const fetchAuthorDetails = (params) => (dispatch, getState, sdk) => {
  try {
    const { listing, cartItems } = params || {};
    const { boxSizes } = listing?.attributes?.publicData || {};
    const { height, length, width, weight } = boxSizes || {};
    const { currentUser } = getState().user || {};
    const { location } = currentUser?.attributes?.profile?.protectedData || {};

    const userName = currentUser?.attributes?.profile
      ? `${currentUser.attributes.profile.displayName}`
      : null;

    const sellerName = listing?.author?.attributes?.profile?.publicData
      ? listing.author.attributes.profile.displayName
      : null;

    const cartItemsWeight = cartItems?.reduce(
      (accumulator, obj) => accumulator + obj.packageWeight,
      0
    );

    // Dispatching request action for fetching rates
    dispatch(fetchRatesRequest());

    // Fetch author data (e.g., their location)
    getAuthorData({ authorId: listing?.author?.id })
      .then((authorData) => {
        const sellerLocation = authorData?.location;

        // Prepare buyer and seller shipping addresses
        const buyerShippingAddress = {
          name: userName,
          street1: location?.address?.split(',')[0],
          street2: `${location?.apartment ? location.apartment + " " : ""}${location?.poBoxNumber || ''}`,
          city: location?.city,
          state: usStates.getStateCodeByStateName(location?.state) || location?.state,
          zip: location?.postalCode,
          country: 'US',
        };

        const sellerShippingAddress = {
          name: sellerName,
          street1: sellerLocation?.address?.split(',')[0],
          city: sellerLocation?.city,
          state: usStates.getStateCodeByStateName(sellerLocation?.state) || sellerLocation?.state,
          zip: sellerLocation?.postalCode,
          country: 'US',
        };

        // Prepare listing dimensions
        const listingDimensions = {
          height,
          length,
          weight: cartItems?.length > 0 ? cartItemsWeight : weight,
          width,
        };

        // Prepare shipment parameters
        const shipmentParams = {
          buyerShippingAddress,
          sellerShippingAddress,
          listingDimensions,
          location,
        };

        // Validate the address using Shippo
        return dispatch(addressValidationWithShippo({
          postalCode: location?.postalCode,
          state: usStates.getStateCodeByStateName(location?.state) || location?.state,
          city: location?.city,
          addressLine1: location?.address?.split(',')[0],
          addressLine2: `${location?.apartment ? location.apartment + " " : ""}${location?.poBoxNumber || ''}`,
          email: currentUser?.attributes?.email,
          firstName: userName,
        })).then((validatedAddress) => {
          if (validatedAddress) {
            // Create shipment only if the address is valid
            return createShipment(shipmentParams);
          } else {
            dispatch(fetchRatesSuccess({ shippingRates: [], defaultShipping: {} }));
            throw new Error('Invalid address');
          }
        });
      })
      .then((shippingRates) => {
        // Dispatch success action with the rates
        dispatch(fetchRatesSuccess({ shippingRates, defaultShipping: shippingRates[0] }));
      })
      .catch((error) => {
        console.error('Error in fetchAuthorDetails:', error);
        // Dispatch error action
        dispatch(fetchRatesError(error));
      });
  } catch (error) {
    console.error('Unexpected error in fetchAuthorDetails:', error);
    // Dispatch error action
    dispatch(fetchRatesError(error));
  }
};


export const confirmPayment = (transactionId, transitionParams = {}) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(confirmPaymentRequest());
  const { paymentIntentId, stripePaymentMethodId, ...rest } = transitionParams || {};


  const bodyParams = {
    id: transactionId,
    transition: transitions.CONFIRM_PAYMENT,
    params: transitionParams,
  };
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };
  // confirmStripePaymentIntent({ paymentIntentId, stripePaymentMethodId }).then((res) => {
  //   if (res) {
  return sdk.transactions
    .transition(bodyParams, queryParams)
    .then(response => {
      const order = response.data.data;
      dispatch(confirmPaymentSuccess(order.id));
      dispatch(confirmTransactionExtraAttributes({ ...rest }));
      return order;
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
  //   }
  // })
};

export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

export const createPaymentIntentWithStripe = (orderParams) => (dispatch, getState, sdk) => {
  const {
    listingId,
    cartItems,
    protectedData,
    totalPaid,
    offerPrice,
    shippingPrice,
    stripeCustomerId,
    couponData,
    transitionName,
    currentUser = {},
    currentListing = {}
  } = orderParams;

  dispatch(initiateOrderRequest());

  const quantityMaybe = { stockReservationQuantity: 1 };
  const bookingParamsMaybe = {};

  // Parameters only for client app's server
  const orderData = {
    stockReservationQuantity: 1,
    deliveryMethod: 'shipping',
    cartItems,
    shippingPrice,
    couponData,
    offerPrice
  };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSuccess = (response, paymentIntentId, clientSecret) => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    const { payoutTotal, createdAt } = order.attributes || {};
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));

    const displayName = currentUser?.attributes?.profile?.displayName || '';
    const actions = [];

    if (offerPrice) {
      actions.push(
        sdk.messages.send({
          transactionId: order?.id,
          content: `You have offered $${offerPrice} for this product. + ${displayName} has offered $${offerPrice} for this product.`,
        }),
        updateTransactionMetadata({
          id: order?.id,
          isProviderRead: false,
          isCustomerRead: true,
        }),
        dispatch(reminderMailNotification({
          txId: order?.id,
          listingName: currentListing?.attributes?.title,
          offerAmount: parseInt(offerPrice),
          saleAmount: currentListing?.attributes?.price?.amount / 100,
          userEmail: getUserDetails(currentListing?.author).email,
          timeStamp: getTimestampAfterAdding14Hours()
        }))
      );
    } else {
      actions.push(
        addOrUpdateTransactionData({
          txId: order?.id?.uuid,
          payoutStatus: "pending",
          paymentStatus: "done",
          payoutAmount: payoutTotal.amount / 100,
          createdAt: moment(createdAt).toDate(),
          paymentAmount: totalPaid / 100
        })
      );
    }

    // Execute all additional actions concurrently
    return Promise.all(actions).then(() => ({
      order,
      paymentIntentId,
      clientSecret
    }));
  };

  const handleError = (e) => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId,
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    return Promise.reject(e);
  };

  return createStripePaymentIntent({
    payment_method_types: ['card'],
    amount: totalPaid,
    currency: 'USD',
    ...(stripeCustomerId ? { customer: stripeCustomerId } : {}),
  })
    .then((intentResponse) => {
      const { paymentIntentId, clientSecret } = intentResponse || {};

      if (!paymentIntentId) {
        return Promise.reject(new Error('PaymentIntent creation failed'));
      }

      // Parameters for Marketplace API
      const transitionParams = {
        listingId,
        ...quantityMaybe,
        ...bookingParamsMaybe,
        protectedData: {
          ...protectedData,
          paymentIntentId: [{ paymentIntentId, amount: totalPaid / 100 }],
        },
      };

      const bodyParams = {
        processAlias: `${DOTS_PROCESS_NAME}/release-1`,
        transition: transitionName,
        params: transitionParams,
      };

      // Initiate privileged action
      return initiatePrivileged({
        isSpeculative: false,
        orderData,
        bodyParams,
        queryParams,
      }).then((response) => handleSuccess(response, paymentIntentId, clientSecret));
    })
    .catch(handleError);
};



/**
 * Initiate transaction against default-inquiry process
 * Note: At this point inquiry transition is made directly against Marketplace API.
 *       So, client app's server is not involved here unlike with transitions including payments.
 *
 * @param {*} inquiryParams contains listingId and protectedData
 * @param {*} processAlias 'default-inquiry/release-1'
 * @param {*} transitionName 'transition/inquire-without-payment'
 * @returns
 */
export const initiateInquiryWithoutPayment = (inquiryParams, processAlias, transitionName) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(initiateInquiryRequest());

  if (!processAlias) {
    const error = new Error('No transaction process attached to listing');
    log.error(error, 'listing-process-missing', {
      listingId: listing?.id?.uuid,
    });
    dispatch(initiateInquiryError(storableError(error)));
    return Promise.reject(error);
  }

  const bodyParams = {
    transition: transitionName,
    processAlias,
    params: inquiryParams,
  };
  const queryParams = {
    include: ['provider'],
    expand: true,
  };

  return sdk.transactions
    .initiate(bodyParams, queryParams)
    .then(response => {
      const transactionId = response.data.data.id;
      dispatch(initiateInquirySuccess());
      return transactionId;
    })
    .catch(e => {
      dispatch(initiateInquiryError(storableError(e)));
      throw e;
    });
};

/**
 * Initiate or transition the speculative transaction with the given
 * booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */

export const getOrderNumber = () => (dispatch, getState, sdk) => {
  try {
    fetchOrderNumber().then(res => {
      dispatch(fetchOrderNumberSuccess(res.orderNumber))
    });
  } catch (error) {
    dispatch(fetchOrderNumberError(error));
  }
};

export const speculateTransaction = (
  orderParams,
  processAlias,
  transactionId,
  transitionName,
  isPrivilegedTransition
) => (dispatch, getState, sdk) => {
  dispatch(speculateTransactionRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;

  const { deliveryMethod, quantity, bookingDates, ...otherOrderParams } = orderParams;
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};
  const bookingParamsMaybe = bookingDates || {};

  // Parameters only for client app's server
  const orderData = deliveryMethod ? { deliveryMethod } : {};

  // Parameters for Marketplace API
  const transitionParams = {
    ...quantityMaybe,
    ...bookingParamsMaybe,
    ...otherOrderParams,
    cardToken: 'CheckoutPage_speculative_card_token',
  };

  const bodyParams = isTransition
    ? {
      id: transactionId,
      transition: transitionName,
      params: transitionParams,
    }
    : {
      processAlias,
      transition: transitionName,
      params: transitionParams,
    };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
  };

  const handleError = e => {
    log.error(e, 'speculate-transaction-failed', {
      listingId: transitionParams.listingId.uuid,
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    return dispatch(speculateTransactionError(storableError(e)));
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transitionSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiateSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};

// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship
export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());
  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(response => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      dispatch(stripeCustomerError(storableError(e)));
    });
};

export const initiateOffer = (orderParams) => async (dispatch, getState, sdk) => {
  try {
    const {
      listingId,
      cartItems,
      protectedData,
      totalPaid,
      offerPrice,
      shippingPrice,
      stripeCustomerId,
      stripePaymentMethodId,
      couponData,
      transitionName,
      currentUser = {},
      currentListing = {},
      config
    } = orderParams;

    dispatch(initiateOrderRequest());

    const quantityMaybe = { stockReservationQuantity: 1 };
    const bookingParamsMaybe = {};

    // Parameters only for client app's server
    const orderData = {
      stockReservationQuantity: 1,
      deliveryMethod: 'shipping',
      cartItems,
      shippingPrice,
      couponData,
      offerPrice
    };

    const queryParams = {
      include: ['booking', 'provider'],
      expand: true,
    };

    const handleSuccess = (response) => {
      const entities = denormalisedResponseEntities(response);
      const order = entities[0];
      dispatch(initiateOrderSuccess(order));
      dispatch(fetchCurrentUserHasOrdersSuccess(true));
      const displayName = currentUser?.attributes?.profile?.displayName || '';
      if (offerPrice) {
        sdk.messages.send({
          transactionId: order?.id,
          content: `You have offered $${offerPrice} for this product. + ${displayName} has offered $${offerPrice} for this product.`,
        });
        updateTransactionMetadata({
          id: order?.id,
          isProviderRead: false,
          isCustomerRead: true,
        });
        dispatch(fetchMessages(order?.id, 1, config)),
          dispatch(reminderMailNotification(
            {
              txId: order?.id,
              listingName: currentListing?.attributes?.title,
              offerAmount: parseInt(offerPrice),
              saleAmount: currentListing?.attributes?.price?.amount / 100, userEmail: getUserDetails(currentListing?.author).email, timeStamp: getTimestampAfterAdding14Hours()
            }
          ))

      }
      return { order };
    };

    const intentResponse = await createStripePaymentIntent({
      payment_method_types: ['card'],
      payment_method: stripePaymentMethodId,
      amount: totalPaid,
      currency: 'USD',
      ...(stripeCustomerId ? { customer: stripeCustomerId } : {}),
    });
    const { paymentIntentId } = intentResponse || {};

    if (intentResponse) {
      // Parameters for Marketplace API
      const transitionParams = {
        listingId,
        ...quantityMaybe,
        ...bookingParamsMaybe,
        protectedData: {
          ...protectedData,
          paymentIntentId: [{ paymentIntentId, amount: totalPaid / 100 }],
        },
      };

      const bodyParams = {
        processAlias: `${DOTS_PROCESS_NAME}/release-1`,
        transition: transitionName,
        params: transitionParams,
      };

      // initiate privileged
      const response = await initiatePrivileged({
        isSpeculative: false,
        orderData,
        bodyParams,
        queryParams,
      });

      return handleSuccess(response);
    }
  } catch (error) {
    dispatch(initiateOrderError(storableError(error)));
  }
};